import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { authService } from "../Components/API/authService";
import api from "../Components/API/apiService";
import { NavLink, useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Background from "../Images/Login.png";
import MobileBackground from "../Images/Cloud4.png";

import { ReactComponent as EyeIcon } from "../Icons/Login_Signup/eye.svg";
import { ReactComponent as EmailIcon } from "../Icons/Login_Signup/email.svg";
import { ReactComponent as Facebook } from "../Icons/Socials/facebook2.svg";
import { ReactComponent as Apple } from "../Icons/Socials/apple.svg";
import { ReactComponent as Google } from "../Icons/Socials/google.svg";
import { ReactComponent as Info } from "../Icons/Login_Signup/Info.svg";

function Signup({ setLoggedIn }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const firstNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordRepeatRef = useRef(null);

  const [errors, setErrors] = useState({
    firstName: false,
    email: false,
    password: false,
    passwordRepeat: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleSubmit = async (e) => {
    setErrors({
      firstName: false,
      email: false,
      password: false,
      passwordRepeat: false,
    });
    setErrorMessage("");
    const firstName = firstNameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const passwordRepeat = passwordRepeatRef.current.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const firstNameError = firstName === "";
    const emailError = !emailRegex.test(email) || email === "";
    const passwordError = password.length < 6 || password === "";
    const passwordRepeatError =
      password !== passwordRepeat || passwordRepeat === "";

    setErrors({
      firstName: firstNameError,
      email: emailError,
      password: passwordError,
      passwordRepeat: passwordRepeatError,
    });

    const errorMessage =
      firstName === "" ||
      email === "" ||
      password === "" ||
      passwordRepeat === ""
        ? "All fields are required"
        : !emailRegex.test(email)
        ? "Invalid email format"
        : firstName.length > 25
        ? "Invalid name"
        : password.length < 6
        ? "Password must be at least 6 characters"
        : passwordRepeat !== password
        ? "Both Passwords must match"
        : "";

    setErrorMessage(errorMessage);

    if (!errorMessage) {
      try {
        const response = await api.post("user/create", {
          firstName,
          email,
          password,
        });
        if (response.success) {
          // Check if the response contains the expected token structure
          if (response.token) {
            authService.setTokens(response.token);
            setLoggedIn(true);
            navigate("/");
          } else {
            setErrorMessage("Invalid server response. Please try again.");
          }
        } else {
          setErrorMessage(
            response.message || "An error occurred during signup."
          );
        }
      } catch (error) {
        console.error("Signup error:", error);
        setErrorMessage("Network error: Unable to connect to the server.");
      }
    }
  };

  return (
    <Container
      style={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.85s ease-in-out",
      }}
    >
      <InnerContainer>
        <CloseButton $theme={theme}>
          <IconContainer onClick={() => navigate("/")}>
            <CloseIcon />
            <ChevronLeftIcon className="mobile" />
          </IconContainer>
        </CloseButton>
        <Title $theme={theme}>
          <h1>Signup</h1>
        </Title>
        <Content>
          <TextBox $theme={theme} className={errors.firstName ? "error" : ""}>
            <input type="text" placeholder="First Name" ref={firstNameRef} />
          </TextBox>
          <TextBox $theme={theme} className={errors.email ? "error" : ""}>
            <input type="email" placeholder="your@mail.com" ref={emailRef} />
            <EmailIcon />
          </TextBox>
          <TextBox $theme={theme} className={errors.password ? "error" : ""}>
            <input type="password" placeholder="Password" ref={passwordRef} />
            <EyeIcon />
          </TextBox>
          <TextBox
            $theme={theme}
            className={errors.passwordRepeat ? "error" : ""}
          >
            <input
              type="password"
              placeholder="Confirm Password"
              ref={passwordRepeatRef}
            />
            <EyeIcon />
          </TextBox>
          <ErrorMessage $theme={theme}>&nbsp;{errorMessage}</ErrorMessage>
          <IconsContainer>
            {/* <p>Or Continue with:</p>
            <div style={{ display: "flex", gap: "15px" }}>
              <Apple />
              <Facebook />
              <Google />
            </div> */}
            <Button $theme={theme} onClick={handleSubmit}>
              Sign Up
            </Button>
          </IconsContainer>
        </Content>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 30px 0;
  min-height: 100vh;

  @media (max-width: 960px) {
    padding: 0;
  }
`;

const InnerContainer = styled.div`
  width: 85%;
  height: 700px;
  margin: 0 auto;
  background: url(${Background}),
    linear-gradient(180deg, #fff 32.29%, #f0f0f0 100%) no-repeat;
  background-size: cover;
  border-radius: 51px;
  box-shadow: 0px 14px 36px 0px #dee4e9;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  row-gap: 50px;
  padding: 60px 100px 0 0;
  position: relative;

  @media (max-width: 960px) {
    background: url(${MobileBackground});
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    padding: 120px 15px 80px 15px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 60px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.$theme.colors.dark};
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    left: 15px;
    top: 40px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  box-shadow: 0px 4px 10px 2px #dedddd;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  > svg {
    width: 25px;
  }

  &:hover {
    box-shadow: none;
  }
  > .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    > svg {
      display: none;
    }
    > .mobile {
      display: block;
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  height: fit-content;
  align-items: flex-end;
  min-width: 45%;

  @media (max-width: 960px) {
    min-width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  > h1 {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 45px;
    font-weight: 700;
    line-height: 100%;
    font-family: ${(props) => props.$theme.secondaryFont};
  }

  > h6 {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 15px;
    font-weight: 100;
    line-height: 150%;

    > a {
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;

const Content = styled.div`
  min-width: 45%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  @media (max-width: 960px) {
    min-width: 100%;
  }
`;

const TextBox = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 21px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 53px;
  background: #eef1f6;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;
  border: 1.5px solid #eef1f6;

  &.error {
    border-color: #ff292e;
  }
  > input {
    background: #eef1f6;
    min-width: 90%;
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 15px;
    padding: 10px 0;

    &::placeholder {
      color: ${(props) => props.$theme.colors.grey2};
      font-size: 15px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 1px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ErrorMessage = styled.div`
  color: #ff292e;
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;
  margin: 5px 0 -10px 0;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;

  > p {
    color: #9da7b1;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    margin-right: 15px;
  }

  > svg {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Button = styled.button`
  padding: 16px 26px;
  background-color: ${(props) => props.$theme.colors.dark};
  color: ${(props) => props.$theme.colors.white};
  font-size: 15px;
  border-radius: 50px;
  min-width: 80px;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  margin: -5px 0 0 auto;

  &:active {
    background-color: lightgray;
    color: black;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export default Signup;
