import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import api from "../Components/API/apiService";

import Newsletter from "../Components/Profile Components/PageComponents/Newsletter";
import NewsletterImg from "../Images/Pages/Newsletter.png";

import { ReactComponent as FacbookIcon } from "../Icons/Socials/facebook.svg";
import { ReactComponent as XIcon } from "../Icons/Socials/x.svg";
import { ReactComponent as GooglePlusIcon } from "../Icons/Socials/g+.svg";
import { ReactComponent as PinterestIcon } from "../Icons/Socials/pintrest.svg";

function Contact() {
  const theme = useTheme();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleFormSubmit = async () => {
    setErrors({ name: false, email: false, message: false });
    setErrorMessage("");
    setSuccessMessage("");
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const nameError = name === "";
    const emailError = !emailRegex.test(email) || email === "";
    const messageError = message === "";

    setErrors({ name: nameError, email: emailError, message: messageError });

    const errorMessage =
      name === "" || email === "" || message === ""
        ? "All fields are required"
        : !emailRegex.test(email)
        ? "Invalid email format"
        : "";

    setErrorMessage(errorMessage);

    // Do not proceed if there are validation errors
    if (errorMessage) {
      return;
    }

    try {
      const response = await api.post("contact", {
        name,
        email,
        message,
      });

      if (response.success) {
        setSuccessMessage(response.message || "Message sent successfully!");
        // Clear form fields
        nameRef.current.value = "";
        emailRef.current.value = "";
        messageRef.current.value = "";
      } else {
        setErrorMessage(
          response.message || "Failed to send message. Please try again."
        );
      }
    } catch (error) {
      console.error("Contact form submission error:", error);
      setErrorMessage("Network error: Unable to connect to the server.");
    }
  };

  return (
    <Container>
      <Header $theme={theme}>
        <h6>About Us</h6>
        <Title $theme={theme}>Get In Touch</Title>
        <Links $theme={theme}>
          <NavLink to="/">Home</NavLink> /
          <NavLink to="/contact">Contact Us</NavLink>
        </Links>
      </Header>
      <Form $theme={theme}>
        <input
          type="text"
          placeholder="Your Name"
          ref={nameRef}
          className={errors.name ? "error" : ""}
        />
        <input
          type="email"
          placeholder="Your Email"
          ref={emailRef}
          className={errors.email ? "error" : ""}
        />
        <textarea
          rows={10}
          placeholder="Your Message"
          ref={messageRef}
          className={errors.message ? "error" : ""}
        />
        <ErrorMessage $theme={theme}>&nbsp;{errorMessage}</ErrorMessage>
        <SuccessMessage $theme={theme}>&nbsp;{successMessage}</SuccessMessage>
        <button onClick={handleFormSubmit}>Send</button>
      </Form>
      <ContactInfo>
        <Item>
          <a href="#">
            <FacbookIcon />
          </a>
          <a href="#">
            <XIcon />
          </a>
          <a href="#">
            <GooglePlusIcon />
          </a>
          <a href="#">
            <PinterestIcon />
          </a>
        </Item>
      </ContactInfo>
      <Newsletter type="img" background={NewsletterImg} />
    </Container>
  );
}

const SuccessMessage = styled.div`
  color: #28a745;
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;
  margin: -5px 0 -10px 0;
`;

const Container = styled.div`
  .newsletter {
    margin-top: -150px;
  }
`;

const Header = styled.div`
  display: flex;
  max-width: 1200px;
  padding: 30px 100px 90px 100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin: 20px auto 0px auto;

  > h6 {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
  }

  @media (max-width: 960px) {
    padding: 30px 15px 50px 15px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 51px;
  font-weight: 700;
  line-height: 100%;
`;

const Links = styled.div`
  display: inline-flex;
  gap: 12px;

  color: ${(props) => props.$theme.colors.grey1};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  > a {
    text-decoration-line: underline;
  }
`;

const Form = styled.div`
  display: flex;
  max-width: 1164px;
  padding: 0px 100px 90px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin: 0px auto;

  > input,
  textarea {
    width: 100%;
    display: flex;
    padding: 18px 21px;
    align-items: center;
    gap: 10px;
    border-radius: 53px;
    background: #eef1f6;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;

    border: 1px solid #eef1f6;

    &.error {
      border-color: #ff292e;
    }
  }

  > textarea {
    border-radius: 27px !important;
  }

  > button {
    width: 100%;
    padding: 18px 24px;
    border-radius: 1000px;
    background: #202228;
    box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
    color: #f6f7f9;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
  }

  @media (max-width: 960px) {
    padding: 0px 15px 90px 15px;
    > button {
      width: 120px;
      margin: 0 auto;
    }
  }
`;

const CheckBox = styled.div`
  margin-top: 10px;
  > span {
    color: #9da7b1;
    font-size: 13px;
    font-weight: 300;
    line-height: 150%;
    margin-left: 35px;
  }

  .custom-checkbox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    margin-bottom: 15px;
  }

  .custom-checkbox input {
    opacity: 0;
    position: absolute;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: #edeff3;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #fff;
    box-sizing: border-box;
  }

  .custom-checkbox input:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    height: 55%;
    width: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    background: radial-gradient(
      130.62% 130.62% at 50% 0%,
      #6dd4f0 0%,
      #6dd4f0 13.02%,
      #1a839f 100%
    );
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;
  }
`;

const ErrorMessage = styled.div`
  color: #ff292e;
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;
  margin: -5px 0 -10px 0;
`;

const ContactInfo = styled.div`
  display: flex;
  width: 100%;
  padding: 42px 0px 204px 0px;
  justify-content: center;
  align-items: center;
  gap: 49px;

  background: #202228;

  @media (max-width: 960px) {
    flex-direction: column;
    gap: 30px;
    > svg {
      display: none;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;

    &:last-child {
      flex-direction: row;
    }
  }

  > .icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border-radius: 52px;
    background: #f6f7f9;
    box-shadow: 0px 3.857px 9px 0px rgba(74, 91, 111, 0.23);
  }

  > div {
    span {
      color: #9da7b1;
      font-size: 10px;
      font-weight: 400;
      line-height: 110%;
    }
    h6 {
      color: #f6f7f9;
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
    }
  }
  > a {
    cursor: pointer;
  }
`;

export default Contact;
