import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, IconButton, Menu, MenuItem } from "@mui/material";
import styled, { ThemeProvider } from "styled-components";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Navbar from "../../Components/Profile Components/Navbar";
import InnerFooter from "../../Components/Profile Components/InnerFooter";
import SegmentPie from "../../Components/Profile Components/Extras/Charts/SegmentPie";
import ProgressPie from "../../Components/Profile Components/Extras/Charts/ProgressPie";

import { ReactComponent as HideBarIcon } from "../../Icons/Profile Icons/HideBarIcon.svg";
import { ReactComponent as ShowBarIcon } from "../../Icons/Profile Icons/ShowBarIcon.svg";
import { ReactComponent as HideLineIcon } from "../../Icons/Profile Icons/HideLineIcon.svg";
import { ReactComponent as ShowLineIcon } from "../../Icons/Profile Icons/ShowLineIcon.svg";
import { ReactComponent as InfoIcon } from "../../Icons/Profile Icons/Info.svg";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  BarChart,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  ComposedChart,
} from "recharts";

import Brain from "../../Components/Profile Components/Extras/Charts/Brain";
import api from "../../Components/API/apiService";

function Statistics() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState(1);
  const [active2, setActive2] = useState(1);
  const [showLine, setShowLine] = useState(false);
  const [showBar, setShowBar] = useState(true);
  const [bars, setBars] = useState([true, true, true]);
  const [resultDate, setResultDate] = useState("2023-10-12");
  const [brainRegions, setBrainRegions] = useState([]);
  const [selectedBrainRegion, setSelectedBrainRegion] = useState(null);
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const navigate = useNavigate();

  const handleDateChange = (e) => {
    setResultDate(e.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchBrainRegions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get("assessment/brainRegions");
        console.log(response);
        if (response.successful && Array.isArray(response.brainRegions)) {
          setBrainRegions(response.brainRegions);
          if (response.brainRegions.length > 0) {
            setSelectedBrainRegion(response.brainRegions[0]);
          }
        } else {
          throw new Error(
            "Failed to fetch brain regions or invalid data received"
          );
        }
      } catch (error) {
        console.error("Error fetching brain regions:", error);
        setError("Failed to fetch brain regions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBrainRegions();
  }, []);

  useEffect(() => {
    const fetchMetrics = async () => {
      if (selectedBrainRegion) {
        setLoading(true);
        setError(null);
        try {
          const response = await api.get(
            `assessment/metrics/${selectedBrainRegion.brainRegionID}`
          );
          console.log(response);
          if (
            response.successful &&
            typeof response.radarChartData === "object"
          ) {
            setMetrics(response.radarChartData);
          } else {
            throw new Error("Failed to fetch metrics or invalid data received");
          }
        } catch (error) {
          console.error("Error fetching metrics:", error);
          setError("Failed to fetch metrics. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMetrics();
  }, [selectedBrainRegion]);

  const handleBrainRegionChange = (region) => {
    setSelectedBrainRegion(region);
    setAnchorEl(null);
  };

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Navbar
        back_label="My Profile"
        back_link="/profile"
        title="Viewing the results of the Brain Zones"
        variant="2"
        username=""
        tooltip="Click on the corresponding zone on the image of the human brain on the right in viewport (you have two perspectives of the brain) to select the corresponding zone and view the results of the selected zone, or select a brain zone from the list that can be opened by hovering the mouse cursor over the name of the brain zone."
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        currentButton={2}
      />
      <Container>
        <ContentBox>
          <Charts>
            <ChartsHeader>
              <h6>Your Results</h6>
              <input
                type="date"
                onChange={handleDateChange}
                value={resultDate}
              />
            </ChartsHeader>
            <ChartsContainer>
              <ProgressPie showInfo={false} />
              <SegmentPie legend={false} />
            </ChartsContainer>
            <ChartsLabel>
              <span>Data_1</span>
              <span>Data_2</span>
            </ChartsLabel>
            <ChartsInfo>
              <div>
                <h6>Correct Answers : </h6>
                <p>
                  22 <span>/ 29</span>
                </p>
              </div>
              <div>
                <h6>Average Answer Time : </h6>
                <p>
                  120 <span>sec</span>
                </p>
              </div>
            </ChartsInfo>
          </Charts>
          <BrainContainer>
            <BrainInfo>
              <DropdownButton onClick={handleClick}>
                {selectedBrainRegion
                  ? selectedBrainRegion.name
                  : "Select Brain Region"}
                <IconButton>
                  {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </DropdownButton>
              <Menu
                id="brain-region-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "250px",
                    boxShadow: "0px 14px 36px #DEE4E9",
                    borderRadius: "15px",
                    padding: "15px",
                  },
                }}
              >
                {brainRegions.map((region) => (
                  <MenuItem
                    key={region.brainRegionID}
                    selected={
                      region.brainRegionID ===
                      selectedBrainRegion?.brainRegionID
                    }
                    onClick={() => handleBrainRegionChange(region)}
                    sx={{
                      fontWeight: "300",
                      fontSize: "14px",
                      lineHeight: "150%",
                    }}
                  >
                    {region.name}
                  </MenuItem>
                ))}
              </Menu>
              {selectedBrainRegion && (
                <div>
                  <p>{selectedBrainRegion.description}</p>
                  <p className="brain_main_info">
                    {selectedBrainRegion.responsibility}
                  </p>
                  <button
                    onClick={() =>
                      navigate(
                        `/profile/statistics/brain/${selectedBrainRegion.name}`
                      )
                    }
                  >
                    Learn More
                  </button>
                </div>
              )}
            </BrainInfo>
            <Brain />
          </BrainContainer>
        </ContentBox>
        <MetricsSection>
          <h2>
            Metrics for{" "}
            {selectedBrainRegion ? selectedBrainRegion.name : "Selected Region"}
          </h2>
          {loading ? (
            <p>Loading metrics...</p>
          ) : (
            <MetricsGrid>
              {Object.entries(metrics).map(([category, data]) => (
                <MetricCategory key={category}>
                  <h3>{category}</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <RadarChart outerRadius={90} data={data}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="name" />
                      <PolarRadiusAxis angle={30} domain={[0, 100]} />
                      <Radar
                        name="Performance"
                        dataKey="performance"
                        stroke="#8884d8"
                        fill="#8884d8"
                        fillOpacity={0.6}
                      />
                    </RadarChart>
                  </ResponsiveContainer>
                </MetricCategory>
              ))}
            </MetricsGrid>
          )}
        </MetricsSection>
      </Container>
    </ThemeProvider>
  );
}

const Container = styled.div`
  padding: 20px 42px;

  @media (max-width: 960px) {
    padding: 20px 5px;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-bottom: 25px;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0 10px;
    > div {
      width: 100%;
    }
  }
`;

const BrainContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  @media (max-width: 960px) {
    flex-direction: column;
    width: 100%;
  }
`;

const BrainInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  > div:nth-child(2) {
    margin-top: 20px;
    p:first-child {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 150%;
      color: #2d5761;
      margin-bottom: 15px;
    }
    p.brain_main_info {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #515357;
    }
    button {
      width: 120px;
      padding: 15px 24px;
      background: #ffffff;
      box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
      border-radius: 1000px;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 1px;
      color: #202228;
      margin-top: 25px;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const DropdownButton = styled.div`
  cursor: pointer;
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 110%;
  letter-spacing: 0.5px;
  color: #202228;
  display: flex;
  align-items: center;

  > button {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 3.95745px 9.23404px rgba(74, 91, 111, 0.23);
    border-radius: 46.1702px;

    > svg {
      color: #000;
    }
  }
`;

const Charts = styled.div`
  max-width: 30%;
  min-width: 315px;
  min-height: 330px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  padding: 27px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 960px) {
    max-width: 100%;
  }
`;

const ChartsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > h6 {
    color: ${(props) => props.theme.colors.dark};
    font-family: ${(props) => props.theme.secondaryFont};
    font-size: 21px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.5px;
  }

  > input {
    font-size: 11px;
    font-weight: 400;
    line-height: 110%;
    width: 100px;
    padding: 6px 12px;
    border-radius: 57px;
    background: #fff;
    box-shadow: 0px 2px 11px 0px rgba(74, 91, 111, 0.09);
  }
`;

const ChartsContainer = styled.div`
  display: flex;
  gap: 15px;

  > div:first-child {
    width: 50%;
    margin-left: -5px;
    > div {
      position: relative;
      > div:nth-child(2) {
        top: 34%;
        left: 32%;
      }
    }
  }

  > div:nth-child(2) {
    > div:first-child {
      position: absolute !important;
    }
    > div:nth-child(2) {
      top: 3%;
    }
  }
`;

const ChartsLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -10px;

  > span {
    color: ${(props) => props.theme.colors.grey2};
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    &:first-child {
      margin-right: 15px;
    }
    &:nth-child(2) {
      margin-left: 15px;
    }
  }
`;

const ChartsInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 10px;
  border-top: 1px solid #e2e5e9;
  margin: 0px 0 -5px 0;

  > div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    justify-content: space-between;

    > h6 {
      color: ${(props) => props.theme.colors.grey1};
      font-size: 14px;
      font-weight: 300;
      line-height: 150%;
    }

    > p {
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;

      > span {
        color: ${(props) => props.theme.colors.grey2};
        font-weight: 300;
      }
    }
  }
`;

const MetricsSection = styled.div`
  margin-top: 50px;
  width: 100%;

  h2 {
    color: ${(props) => props.theme.colors.dark};
    font-family: ${(props) => props.theme.secondaryFont};
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    h2 {
      font-size: 20px;
    }
  }
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const MetricCategory = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.15);

  h3 {
    color: ${(props) => props.theme.colors.dark};
    font-family: ${(props) => props.theme.secondaryFont};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  @media (max-width: 960px) {
    padding: 15px;

    h3 {
      font-size: 16px;
    }
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

export default Statistics;
