import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import Navbar from "../../Components/Profile Components/Navbar";
import InnerFooter from "../../Components/Profile Components/InnerFooter";

import Table from "../../Components/Profile Components/Extras/Table";
import SegmentPie from "../../Components/Profile Components/Extras/Charts/SegmentPie";
import ProgressPie from "../../Components/Profile Components/Extras/Charts/ProgressPie";
import ProgressLineChart from "../../Components/Profile Components/Extras/Charts/ProgressLineChart";

import api from "../../Components/API/apiService"; // Import the api service

function Overview() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to detect location changes

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  const startNewAssessment = async () => {
    try {
      const response = await api.post("assessment/create");
      if (response.success) {
        const userAssessmentID = response.assessment;
        navigate(`/assessment/${userAssessmentID}`);
      } else {
        console.error("Failed to create assessment");
      }
    } catch (error) {
      console.error("Error creating assessment:", error);
    }
  };

  // useEffect to fetch data on location change
  useEffect(() => {
    const getUserAssessmentData = async () => {
      setLoading(true); // Set loading to true when fetching data
      try {
        const response = await api.get("assessment/user");
        if (response.successful) {
          setData(response.assessments);
        }
      } catch (error) {
        console.error("Error fetching assessment data:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };

    getUserAssessmentData();
  }, [location]); // Trigger useEffect on location change

  if (loading) {
    return <p>Loading...</p>; // Display loading message while data is being fetched
  }

  return (
    <>
      <Navbar
        back_label="My Profile"
        back_link="/profile"
        title="Assessment Overview"
        variant="2"
        username=""
        tooltip="Click on the corresponding zone on the image of the human brain on the right in viewport (you have two perspectives of the brain) to select the corresponding zone and view the results of the selected zone, or select a brain zone from the list that can be opened by hovering the mouse cursor over the name of the brain zone."
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        currentButton={1}
      />
      <Container>
        {/* <Content>
          <Column $theme={theme}>
            <h4>Enhancing Accuracy Through Multiple Assessments</h4>
            <p>
              Your cognitive blueprint becomes more accurate with each
              assessment you take. Multiple assessments allow us to detect
              subtle changes and patterns in your cognitive abilities over time.
              This ongoing process contributes to a more comprehensive and
              nuanced understanding of your cognitive health, providing
              increasingly valuable insights with each session.
            </p>
            <h4>Brain Regions We Measure</h4>
            <p>
              We evaluate a wide range of brain regions, including the
              Prefrontal Cortex, Temporal Lobes, Parietal Lobes, Hippocampus,
              Anterior Cingulate Cortex, Occipital Lobes, Cerebellum, Basal
              Ganglia, Amygdala, Thalamus, Broca's and Wernicke's Areas, and the
              Insular Cortex. This comprehensive approach creates a holistic
              view of your cognitive abilities, from decision-making and memory
              to emotional processing and language skills.
            </p>
            <h4>Personalized Assessment Approach</h4>
            <p>
              Our assessments adapt based on your previous results, focusing on
              areas needing more insight or showing potential for growth. This
              personalized approach ensures each session provides meaningful,
              targeted information about your brain's functioning. By tailoring
              assessments to your specific cognitive profile, we continuously
              improve the accuracy and relevance of your cognitive blueprint.
            </p>
          </Column>
          <Column $theme={theme}>
            <Charts>
              <Chart>
                <ProgressPie showInfo={true} />
              </Chart>
              <Chart>
                <SegmentPie legend={true} />
              </Chart>
            </Charts>
            <LineMobile />
            <ProgressContainer>
              <ProgressHeader>
                <ProgressTitle $theme={theme}>
                  <h4>Your Progress</h4>
                  <p>Subtitle of Graph</p>
                </ProgressTitle>
                <Switcher>
                  <SwitchButton
                    $theme={theme}
                    onClick={() => handleButtonClick(1)}
                    className={activeButton === 1 ? "active" : ""}
                  >
                    Days
                  </SwitchButton>
                  <SwitchButton
                    $theme={theme}
                    onClick={() => handleButtonClick(2)}
                    className={activeButton === 2 ? "active" : ""}
                  >
                    Months
                  </SwitchButton>
                </Switcher>
              </ProgressHeader>
              <ProgressLineChart />
            </ProgressContainer>
          </Column>
        </Content> */}

        <Header $theme={theme}>
          <h4></h4>
          <button onClick={startNewAssessment}>Start New Assessment</button>
        </Header>
        <Table />
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 10px 42px;
  position: relative;

  @media (max-width: 960px) {
    padding: 50px 10px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  > h4 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 21px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.5px;

    @media (max-width: 960px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 110%;
    }
  }

  > button {
    padding: 15px 24px;
    border-radius: 1000px;
    background: #fff;
    box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
    transition: 0.3s all ease-in-out;

    &:hover {
      box-shadow: none;
    }

    @media (max-width: 960px) {
      font-size: 12px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: row;
  gap: 25px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  padding: 25px 27px;
  margin-top: 5px;
  margin-bottom: 35px;

  > div:first-child {
    overflow-y: auto;
    padding-right: 20px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6dd4f0;
    }
    &::-webkit-scrollbar-track {
      background-color: #eef1f6;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #4fa3c9;
    }

    @media (max-width: 960px) {
      height: 40vh;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 21px 9px;
    border-radius: 27px;
    max-height: fit-content;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 50%;

  @media (max-width: 960px) {
    width: 100%;
  }

  > h2 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 21px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.5px;
  }

  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
  }

  > p {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
  }
`;

const Charts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  > div {
    width: 50%;
  }

  @media (max-width: 960px) {
    flex-direction: row-reverse;
  }
`;

const Chart = styled.div`
  position: relative;
  width: fit-content;

  &:last-child div div:not(.legend_item) {
    position: absolute !important;
    &:nth-child(2) {
      left: 5.5px;
      top: 5.5px;
    }
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
`;

const ProgressHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
`;

const ProgressTitle = styled.div`
  > h4 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 21px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.5px;
  }

  > p {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
  }
`;

const Switcher = styled.div`
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 2px;
  border-radius: 1000px;
  background: #eef1f6;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #fff;
  transform: scale(0.75);
  height: fit-content;
  max-width: 180px;
`;

const SwitchButton = styled.div`
  display: flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
  border-radius: 1000px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 50%;
  }

  &.active {
    background: ${(props) => props.$theme.colors.dark};
    color: #f6f7f9;
  }
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 1.5px;
  background: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);
  margin-top: -10px;
`;

const LineMobile = styled.div`
  width: 100%;
  margin: 80px auto 20px auto;
  height: 1.5px;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);

  @media (min-width: 960px) {
    display: none;
  }
`;

const Recommendations = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 80px;
  width: 100%;
  margin-top: 125px;

  @media (max-width: 960px) {
    flex-direction: column;
    gap: 45px;
    margin-top: 50px;
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  > div:first-child h1 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 45px;
    font-weight: 700;
    line-height: 100%;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 110%;
    }
  }

  > div:first-child p {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 15px;
    font-weight: 300;
    line-height: 150%;
    width: 375px;
  }
`;

const Boxes = styled.div`
  width: -webkit-fill-available;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 27px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  padding: 30px;
  align-items: flex-start;
  gap: 21px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);

  > h4 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.5px;
  }

  > p {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
  }
`;

export default Overview;
