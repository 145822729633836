import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Popover,
} from "@mui/material";

import ButtonDark from "./Buttons/ButtonDark";
import ButtonLight from "./Buttons/ButtonLight";
import api from "./API/apiService";
import { authService } from "./API/authService";
import HamburgerMenu from "./Buttons/HamburgerMenu";

import LogoImg from "../Images/Logo.png";
import Cloud1 from "../Images/Cloud1.png";
import Cloud2 from "../Images/Cloud2.png";
import AvatarImg from "../Images/Profile/avatar.png";
import "../Styles/Navbar.css";

import { ReactComponent as HomeIcon } from "../Icons/Home.svg";
import { ReactComponent as AccountIcon } from "../Icons/Account.svg";
import { ReactComponent as AboutIcon } from "../Icons/About.svg";
import { ReactComponent as ContactIcon } from "../Icons/Contact.svg";
import { ReactComponent as InstructionIcon } from "../Icons/Instruction.svg";
import { ReactComponent as PricingIcon } from "../Icons/Pricing.svg";
import { ReactComponent as TermsIcon } from "../Icons/Terms.svg";
import { ReactComponent as ThumbsUpIcon } from "../Icons/ThumbsUp.svg";
import { ReactComponent as UserIcon } from "../Icons/Profile Icons/User.svg";
import { ReactComponent as SaveIcon } from "../Icons/Profile Icons/Save.svg";
import { ReactComponent as AnalyticsIcon } from "../Icons/Profile Icons/Analytics.svg";
import { ReactComponent as LogoutIcon } from "../Icons/Profile Icons/Logout.svg";

const NavBar = ({ loggedIn, setLoggedIn }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userID, setUserID] = useState(null);
  const [userImage, setUserImage] = useState(AvatarImg);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const open = Boolean(dropDownOpen);
  const id = open ? "simple-popover" : undefined;

  const handleLogout = async () => {
    try {
      await api.post("authenticate/logout");
      // Proceed with logout regardless of server response
    } catch (error) {
      console.error("Error during logout:", error);
      // Proceed with logout even if there's an error
    } finally {
      // Always perform these actions
      authService.logout();
      setLoggedIn(false);
      setUserName(null);
      setUserID(null);
      setUserImage(AvatarImg);
      navigate("/");
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const checkLoginStatus = async () => {
      try {
        const response = await api.get("authenticate/status", {
          signal: abortController.signal,
        });
        if (response && response.isLoggedIn) {
          setLoggedIn(true);
          setUserName(response.username);
          setUserID(response.userID);
          response.image && setUserImage(response.image);
        } else {
          setLoggedIn(false);
          setUserName(null);
          setUserID(null);
          setUserImage(AvatarImg);
        }
      } catch (error) {
        if (error.name === "AbortError") return;
        console.error("Error checking login status:", error);
        setLoggedIn(false);
        setUserName(null);
        setUserID(null);
        setUserImage(AvatarImg);
      }
    };

    checkLoginStatus();

    return () => abortController.abort();
  }, [setLoggedIn]);

  return (
    <header>
      {!isMobile ? (
        <DesktopNav>
          <div style={{ display: "flex", gap: "80px" }}>
            <CloudImage src={Cloud1} style={{ right: "60%", top: 0 }} />
            <Logo src={LogoImg} onClick={() => navigate("/")} alt="Logo" />
            <Links $theme={theme}>
              <NavLink to="/about">About Us</NavLink>
              <NavLink to="/contact">Contacts</NavLink>
              {/* <NavLink to="/instruction">Instructions</NavLink> */}
            </Links>
          </div>
          <ButtonGroup>
            <ButtonLight link="/profile/overview">
              {!loggedIn ? "Start Assessment" : "Assessment"}
            </ButtonLight>
            {loggedIn ? (
              <Avatar
                src={userImage}
                alt="User Avatar"
                aria-describedby={id}
                onClick={(e) => setDropDownOpen(e.currentTarget)}
              />
            ) : (
              <ButtonDark link="/login">Log In</ButtonDark>
            )}
          </ButtonGroup>
          {loggedIn && (
            <Dropdown
              id={id}
              open={open}
              anchorEl={dropDownOpen}
              onClose={() => setDropDownOpen(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              BackdropProps={{ invisible: true }}
              $theme={theme}
            >
              <List>
                <DropdownItem
                  to="/profile"
                  icon={<UserIcon />}
                  onClick={() => setDropDownOpen(false)}
                >
                  {userName || "General"}
                </DropdownItem>
                <DropdownItem
                  to="/profile/overview"
                  icon={<SaveIcon />}
                  onClick={() => setDropDownOpen(false)}
                >
                  Assessments
                </DropdownItem>
                <DropdownItem
                  to="/profile/statistics"
                  icon={<AnalyticsIcon />}
                  onClick={() => setDropDownOpen(false)}
                >
                  Analytics
                </DropdownItem>
                <DropdownItem icon={<LogoutIcon />} onClick={handleLogout}>
                  Logout
                </DropdownItem>
              </List>
            </Dropdown>
          )}
          <CloudImage src={Cloud2} style={{ left: "55%" }} />
        </DesktopNav>
      ) : (
        <MobileNav>
          <StyledDrawer
            PaperProps={{
              sx: {
                width: "90%",
                paddingTop: "110px",
                backgroundColor: theme.background,
              },
            }}
            BackdropProps={{ invisible: true }}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <List>
              <MobileMenuItem
                to="/login"
                icon={<AccountIcon />}
                onClick={() => setMenuOpen(false)}
              >
                <span>Account</span>
                <SubText>Log In or Sign Up</SubText>
              </MobileMenuItem>
              <Line />
              <BlueBar />
              <MobileMenuItem
                to="/"
                icon={<HomeIcon />}
                onClick={() => setMenuOpen(false)}
              >
                Home
              </MobileMenuItem>
              <MobileMenuItem
                to="/about"
                icon={<AboutIcon />}
                onClick={() => setMenuOpen(false)}
              >
                About Us
              </MobileMenuItem>
              <MobileMenuItem
                to="/pricing"
                icon={<PricingIcon />}
                onClick={() => setMenuOpen(false)}
              >
                Pricing
              </MobileMenuItem>
              <MobileMenuItem
                to="/feedback"
                icon={<ThumbsUpIcon />}
                onClick={() => setMenuOpen(false)}
              >
                Feedbacks
              </MobileMenuItem>
              <MobileMenuItem
                to="/contact"
                icon={<ContactIcon />}
                onClick={() => setMenuOpen(false)}
              >
                Contacts
              </MobileMenuItem>
              <MobileMenuItem
                to="/terms"
                icon={<TermsIcon />}
                onClick={() => setMenuOpen(false)}
              >
                Terms of Use
              </MobileMenuItem>
              <MobileMenuItem
                to="/instruction"
                icon={<InstructionIcon />}
                onClick={() => setMenuOpen(false)}
                style={{ marginBottom: "60px" }}
              >
                Instruction
              </MobileMenuItem>
              <Line />
              <MobileMenuItem
                to="/faq"
                icon={<ThumbsUpIcon />}
                onClick={() => setMenuOpen(false)}
              >
                F. A. Q.
              </MobileMenuItem>
              <MobileMenuItem
                to="/test"
                icon={<ThumbsUpIcon />}
                onClick={() => setMenuOpen(false)}
              >
                Start Test
              </MobileMenuItem>
            </List>
          </StyledDrawer>
          <HamburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          {loggedIn ? (
            <Avatar
              src={userImage}
              alt="User Avatar"
              aria-describedby={id}
              onClick={(e) => setDropDownOpen(e.currentTarget)}
              className="avatar"
            />
          ) : (
            <ButtonDark link="/login">Log In</ButtonDark>
          )}
          <ButtonLight link="/assessment">
            {!loggedIn ? "Start Assessment" : "Assessment"}
          </ButtonLight>
          {loggedIn && (
            <Dropdown
              id={id}
              open={open}
              anchorEl={dropDownOpen}
              onClose={() => setDropDownOpen(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              BackdropProps={{ invisible: true }}
              $theme={theme}
            >
              <List>
                <DropdownItem
                  to="/profile"
                  icon={<UserIcon />}
                  onClick={() => setDropDownOpen(false)}
                >
                  {userName || "General"}
                </DropdownItem>
                <DropdownItem
                  to="/profile/overview"
                  icon={<SaveIcon />}
                  onClick={() => setDropDownOpen(false)}
                >
                  Assessments
                </DropdownItem>
                <DropdownItem
                  to="/profile/statistics"
                  icon={<AnalyticsIcon />}
                  onClick={() => setDropDownOpen(false)}
                >
                  Analytics
                </DropdownItem>
                <DropdownItem icon={<LogoutIcon />} onClick={handleLogout}>
                  Logout
                </DropdownItem>
              </List>
            </Dropdown>
          )}
          <Cloud2Mobile src={Cloud2} />
        </MobileNav>
      )}
    </header>
  );
};

NavBar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  setLoggedIn: PropTypes.func.isRequired,
};

const DesktopNav = styled.div`
  width: 100%;
  padding: 0 40px;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MobileNav = styled.div`
  width: 100%;
  padding: 55px 15px;
  > button,
  .avatar {
    float: right;
    margin-top: -12px;
  }

  .avatar {
    margin-left: 15px;
  }
`;

const Avatar = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 1000px;
  object-fit: cover;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  cursor: pointer;
`;

const Cloud2Mobile = styled.img`
  position: absolute;
  right: -100px;
  z-index: -1;
  top: -100px;
  @media (max-width: 600px) {
    position: absolute;
    right: -143px;
    z-index: -1;
    width: 100%;
    height: auto;
    top: -66px;
  }
`;

const Logo = styled.img`
  width: 155px;
  margin-top: 5px;
  cursor: pointer;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 27px;
  padding: 10px 0;
  > a {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 16px;
    letter-spacing: 1px;
    &:hover {
      color: ${(props) => props.$theme.colors.grey2};
    }
  }
  > a.active {
    color: ${(props) => props.$theme.colors.blue};
    margin-top: 1px;
    &:hover {
      color: ${(props) => props.$theme.colors.blue};
    }
    &:after {
      content: "";
      display: block;
      width: auto;
      height: 2px;
      background-color: ${(props) => props.$theme.colors.blue};
      margin-top: 1px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  width: fit-content;
`;

const Dropdown = styled(Popover)`
  > .MuiPaper-root {
    background: #fefefeed;
    border-radius: 18px;
    padding: 0px 10px;
    margin-top: 20px;
    margin-left: -20px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);

  > svg {
    width: 24px;
    height: 24px;
  }
`;

const Line = styled.div`
  width: 92%;
  margin: 18px auto;
  height: 1px;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);
`;

const BlueBar = styled.div`
  width: 6px;
  height: 54px;
  position: absolute;
  right: 0;
  margin-top: -15px;
  border-radius: 15px 0px 0px 10px;
  background: radial-gradient(
    130.62% 130.62% at 50% 0%,
    #6dd4f0 0%,
    #6dd4f0 13.02%,
    #1a839f 100%
  );
`;

const CloudImage = styled.img`
  position: absolute;
  z-index: -1;
`;

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: 90%;
    padding-top: 110px;
    background-color: ${(props) => props.theme.background};
  }
`;

const DropdownItem = ({ to, icon, children, onClick }) => (
  <ListItem onClick={onClick}>
    <ListItemText>
      <IconContainer>{icon}</IconContainer>
      <NavLink to={to}>{children}</NavLink>
    </ListItemText>
  </ListItem>
);

const MobileMenuItem = styled(({ icon, children, ...props }) => (
  <ListItem {...props}>
    <ListItemText>
      {icon}
      {typeof children === "string" ? (
        <NavLink to={props.to}>{children}</NavLink>
      ) : (
        children
      )}
    </ListItemText>
  </ListItem>
))`
  padding: 0;

  .MuiListItemText-root {
    margin: 0;
    display: flex;
    align-items: center;
  }

  svg {
    width: auto;
    height: 75px;
    margin: -5px 0 -15px 0px;
  }

  a {
    color: #515357;
    font-size: 16px;
    line-height: 150%;
  }
`;

const SubText = styled.span`
  font-size: 11px;
  color: #9da7b1;
  margin: -2px 0;
`;

export default NavBar;
