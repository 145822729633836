import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

import Header from "../Components/About Components/Header";
import Newsletter from "../Components/Profile Components/PageComponents/Newsletter";
import NewsletterImg from "../Images/Pages/Newsletter.png";
import BrainImg1 from "../Images/Pages/About_Brain.png";
import BrainBackground from "../Images/Pages/About_Brain-Back.png";
import BrainBackground2 from "../Images/Pages/About_Brain-Back2.png";
import Carousel from "../Components/About Components/Carousel";
import ButtonDark from "../Components/Buttons/ButtonDark";

import SouthEastIcon from "@mui/icons-material/SouthEast";
import { ReactComponent as CalendarIcon } from "../Icons/Calendar.svg";
import FAQ from "../Components/About Components/FAQ";

function About() {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Container>
      <Header activeLink={activeLink} setActiveLink={setActiveLink} />
      <MissionContainer id="mission" $theme={theme}>
        <div className="mission_mobile">
          <div>
            <h2>Intellarity :</h2>
            <br></br> <h3>Evolve with US!</h3>
          </div>
          <img src={BrainImg1} alt="Brain Image" />
        </div>
        <div className="mission_desktop">
          <img src={BrainImg1} alt="Brain Image" />
        </div>
        <MissionContent $theme={theme}>
          <h2>
            Intellarity :<br></br> <span>Evolve with US!</span>
          </h2>
          <div className="row">
            <div className="column">
              <h4>We Are Redefining the Future of Learning</h4>
              <p>
                Welcome to Intellarity, the vanguard of a learning revolution.
                Our pioneering platform is not merely an incremental advancement
                in e-learning; it's a seismic shift to reshape the fabric of
                education and cognitive science. We recognize that each
                individual is a unique cognitive universe deserving a tailored
                educational journey. By mapping your cognitive blueprint, we're
                not just enhancing your learning experience; we're reinventing
                it.
              </p>
            </div>
            <div className="column">
              <h4>Objective</h4>
              <p>
                Intellarity's mission goes beyond traditional educational norms.
                We strive to make learning not only more effective but
                profoundly personal. Our innovative approach is grounded in the
                belief that each person's learning pattern, attention span, and
                information retention capacity is distinct. By creating a
                learning path that’s exclusively yours, we're not just
                educating; we're revolutionizing how knowledge is consumed and
                retained.
              </p>
            </div>
          </div>
          {/* <TestingButton>
            <span>Start Test Now</span>
            <ArrowTilted $theme={theme}>
              <SouthEastIcon />
            </ArrowTilted>
          </TestingButton> */}
        </MissionContent>
      </MissionContainer>
      <ExperienceContainer id="mission">
        <TitleContainer $theme={theme}>
          <h2>The Intellarity Experience</h2>
          <h6>
            Welcome to Intellarity, the vanguard of a learning revolution. Our
            pioneering platform is not merely an incremental
          </h6>
        </TitleContainer>
        <ExperienceCards $theme={theme}>
          <div>
            <span>01</span>
            <div>
              Groundbreaking Initial Assessment:
              <ul>
                <li>
                  Delve deep into your cognitive and learning profiles through a
                  meticulously crafted online assessment.
                </li>
                <li>
                  Engage in various written, visual, audible, and kinesthetic
                  tests to harness a holistic view of your learning prowess.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <span>02</span>
            <div>
              Unparalleled Personalized Learning Pathways:
              <ul>
                <li>
                  With your assessment results, a bespoke learning pathway is
                  charted, mirroring your strengths and predilections. This is
                  not mere customization; it's a cognitive symphony tailored to
                  your brain's rhythms.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <span>03</span>
            <div>
              Adaptive Learning Journey:
              <ul>
                <li>
                  As you evolve, so does Intellarity. Your pathway adapts in
                  real-time, refining your learning experience to unmatched
                  precision.
                </li>
              </ul>
            </div>
          </div>
        </ExperienceCards>
      </ExperienceContainer>
      {/* <TeamContainer id="team">
        <TitleContainer $theme={theme}>
          <h2>Meet Our Team</h2>
          <h6>Meet the team driving a learning revolution.</h6>
        </TitleContainer>
        <Carousel />
      </TeamContainer> */}
      <FeaturesContainer id="why">
        <TitleContainer $theme={theme}>
          <h2>Why Intellarity is a Game-Changer</h2>
          <h6>
            In essence, Intellarity is not just another platform—it's the
            future. We're here to redefine the terrains of learning and
            development, making exquisitely personalized, efficient, and
            immersive education a reality for everyone.
          </h6>
          {/* <ButtonDark link="/assessment">Start Assessment</ButtonDark> */}
        </TitleContainer>
        <FeatureCards>
          <FeatureCard $theme={theme}>
            <FeatureCardHeader $theme={theme}>
              <div>
                <div>
                  <CalendarIcon />
                </div>
                <h4>Sweeping Impact</h4>
              </div>
            </FeatureCardHeader>
            <p>
              Schools, businesses, and institutions stand on the precipice of a
              transformative era with Intellarity. Whether you're a student, a
              professional, or simply someone in pursuit of knowledge, our
              platform is crafted for you.
            </p>
          </FeatureCard>
          <FeatureCard $theme={theme}>
            <FeatureCardHeader $theme={theme}>
              <div>
                <div>
                  <CalendarIcon />
                </div>
                <h4>Lasting Impressions</h4>
              </div>
            </FeatureCardHeader>
            <p>
              Our platform ensures you don't just learn but deeply internalize
              knowledge. The result? Profound learning outcomes that leave an
              indelible mark.
            </p>
          </FeatureCard>
          <FeatureCard $theme={theme}>
            <FeatureCardHeader $theme={theme}>
              <div>
                <div>
                  <CalendarIcon />
                </div>
                <h4>More Than Learning - It's an Evolution</h4>
              </div>
            </FeatureCardHeader>
            <p>
              Intellarity transcends traditional e-learning confines. We’re
              pioneering a personalized learning revolution. You're not just
              learning; you're experiencing cognitive evolution.
            </p>
          </FeatureCard>
          <FeatureCard $theme={theme}>
            <FeatureCardHeader $theme={theme}>
              <div>
                <div>
                  <CalendarIcon />
                </div>
                <h4>A Vibrant Learning Odyssey</h4>
              </div>
            </FeatureCardHeader>
            <p>
              Say goodbye to mundane learning. Embark on an exhilarating journey
              of growth, wonder, and discovery with Intellarity.
            </p>
          </FeatureCard>
          <FeatureCard $theme={theme}>
            <FeatureCardHeader $theme={theme}>
              <div>
                <div>
                  <CalendarIcon />
                </div>
                <h4>A Panoramic Influence</h4>
              </div>
            </FeatureCardHeader>
            <p>
              Beyond academia, our innovative model is primed to enrich
              businesses and organizations, fortifying workforce training and
              upskilling, ensuring teams are ever-ready for future challenges.
            </p>
          </FeatureCard>
        </FeatureCards>
      </FeaturesContainer>
      <FutureContainer $src={BrainBackground} $src2={BrainBackground2}>
        <div></div>
        <TitleContainer $theme={theme}>
          <h2>Embrace the future.</h2>
          <h6>
            <b style={{ marginBottom: "10px" }}>
              Embark on your revolutionary learning journey with Intellarity.
            </b>
            <br></br>
            <br></br>
            Discover your unique cognitive blueprint and unleash your
            unparalleled potential. Welcome to the learning revolution.
          </h6>
          {/* <ButtonDark link="/assessment">Start Assessment</ButtonDark> */}
        </TitleContainer>
      </FutureContainer>
      <FAQ />
      <Newsletter type="img" background={NewsletterImg} />
    </Container>
  );
}

const Container = styled.div`
  > .newsletter {
    margin-top: -140px;
    @media (max-width: 960px) {
      margin-top: -60px;
      margin-bottom: 80px;
    }
  }
`;

const MissionContainer = styled.section`
  max-width: 1440px;
  min-height: fit-content;
  padding: 81px 120px;
  display: flex;
  flex-direction: row;
  gap: 30px;

  > div.mission_desktop {
    width: 40%;
    img {
      width: -webkit-fill-available;
      margin-right: -30px;
      margin-left: -30px;
    }
  }

  > .mission_mobile {
    display: none;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 10px 15px;
    position: relative;
    > div:first-child {
      width: 100%;
    }

    > .mission_desktop {
      display: none;
    }

    > .mission_mobile {
      display: flex;
      img {
        margin-top: 20px;
        margin-left: -80px !important;
        margin-right: 0 !important;
      }
      h2 {
        font-family: ${(props) => props.$theme.secondaryFont};
        font-weight: 700;
        font-size: 42px;
        line-height: 100%;
        width: 200px;
        background: radial-gradient(
            75% 274.73% at -25% 50%,
            #ffe2b8 0%,
            rgba(246, 247, 249, 0) 100%
          ),
          radial-gradient(
            130.62% 130.62% at 50% 0%,
            #6dd4f0 0%,
            #6dd4f0 13.02%,
            #1a839f 100%
          );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      h3 {
        font-family: ${(props) => props.$theme.secondaryFont};
        font-weight: 700;
        font-size: 42px;
        line-height: 100%;
        max-width: 120px;
        font-family: ${(props) => props.$theme.secondaryFont};
        color: ${(props) => props.$theme.colors.dark};
        -webkit-text-fill-color: ${(props) => props.$theme.colors.dark};
        width: fit-content;
      }
    }
  }
`;

const MissionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 60%;

  .row {
    display: flex;
    flex-direction: row;
    gap: 27px;

    .column {
      width: 50%;

      h4 {
        color: ${(props) => props.$theme.colors.dark};
        font-family: ${(props) => props.$theme.secondaryFont};
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 15px;
        height: 50px;
      }

      p {
        color: ${(props) => props.$theme.colors.grey1};
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
      }
    }
  }

  h2 {
    font-family: ${(props) => props.$theme.secondaryFont};
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
    background: radial-gradient(
        75% 274.73% at -25% 50%,
        #ffe2b8 0%,
        rgba(246, 247, 249, 0) 100%
      ),
      radial-gradient(
        130.62% 130.62% at 50% 0%,
        #6dd4f0 0%,
        #6dd4f0 13.02%,
        #1a839f 100%
      );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    span {
      font-family: ${(props) => props.$theme.secondaryFont};
      color: ${(props) => props.$theme.colors.dark};
      -webkit-text-fill-color: ${(props) => props.$theme.colors.dark};
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-top: 50px;
    h2 {
      display: none;
    }

    .row {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .column {
        width: 100%;

        h4 {
          font-weight: 700;
          font-size: 24px;
          line-height: 110%;
          height: unset;
        }
      }
    }
  }
`;

const TestingButton = styled.div`
  width: 240px;
  height: 57px;
  display: inline-flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 1000px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 30px;

  @media (max-width: 960px) {
    align-self: center;
  }
`;

const ArrowTilted = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.$theme.colors.accent};
  width: 57px;
  height: 57px;
  margin: 0 -43px 0px -4px;
  box-shadow: 1px 5px 10px -2px ${(props) => props.$theme.colors.accent};
  > svg {
    color: #fff;
  }
`;

const ExperienceContainer = styled.section`
  max-width: 1440px;
  min-height: fit-content;
  padding: 81px 120px;
  display: flex;
  flex-direction: column;

  > div:first-child {
    align-self: flex-end;
    text-align: right;

    @media (max-width: 960px) {
      align-self: flex-start;
      text-align: left;
    }
  }

  @media (max-width: 1100px) {
    padding: 100px 15px;
  }
`;

const TitleContainer = styled.div`
  max-width: 310px;
  display: flex;
  flex-direction: column;

  h2 {
    font-family: ${(props) => props.$theme.secondaryFont};
    color: ${(props) => props.$theme.colors.dark};
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
    margin-bottom: 15px;
  }

  h6 {
    color: ${(props) => props.$theme.colors.grey1};
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  button {
    margin-top: 50px;
    @media (max-width: 960px) {
      margin: 50px auto 0 auto;
    }
  }
`;

const ExperienceCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 27px;
  margin-top: 75px;
  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 30px 21px;
    gap: 21px;
    width: 380px;
    height: fit-content;
    min-height: 257px;
    left: 140px;
    background: #ffffff;
    box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
    border-radius: 36px;

    > span {
      font-family: ${(props) => props.$theme.secondaryFont};
      color: ${(props) => props.$theme.colors.dark};
      font-weight: 600;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: 0.5px;
    }

    > div {
      font-family: ${(props) => props.$theme.secondaryFont};
      color: ${(props) => props.$theme.colors.dark};
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-top: -2px;
      max-width: 280px;
      ul {
        margin-top: 15px;
        margin-left: 15px;
        li {
          color: ${(props) => props.$theme.colors.grey1};
          font-family: "Helvetica";
          font-weight: 300;
          font-size: 14px;
          line-height: 150%;
        }
      }
    }
  }

  > div:first-child {
    margin-top: -200px;
  }

  > div:nth-child(2) {
    margin-top: -100px;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    > div {
      margin-top: 0 !important;
      width: 400px;
    }
  }
`;

const TeamContainer = styled.section`
  max-width: 1440px;
  min-height: fit-content;
  padding: 81px 120px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 960px) {
    padding: 80px 15px;
  }
`;

const FeaturesContainer = styled.section`
  max-width: 1440px;
  min-height: fit-content;
  padding: 200px 120px 20px 120px;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 100px;
  justify-content: space-between;

  > div:first-child {
    width: 32%;
    max-width: none;
  }

  > div:nth-child(2) {
    width: 60%;
  }

  @media (max-width: 960px) {
    padding: 80px 15px;
    flex-direction: column;
    > div {
      width: 100% !important;
    }
  }
`;

const FeatureCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 27px;
  justify-items: center;

  @media (max-width: 680px) {
    justify-items: center;
    grid-template-columns: 1fr;
    > div {
      width: 100%;
    }
  }

  @media (min-width: 960px) {
    justify-items: end;
    > div:nth-child(5) {
      grid-column: 2;
      margin-top: -100px;
    }
    > div:nth-child(even) {
      margin-top: -100px;
    }
  }
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 21px 22px;
  gap: 15px;
  width: 330px;
  height: fit-content;
  min-height: 240px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
  border-radius: 27px;

  > p {
    color: ${(props) => props.$theme.colors.grey1};
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  @media (max-width: 960px) and (min-width: 768px) {
    width: 400px;
  }
  @media (max-width: 768px) {
    width: 320px;
  }

  @media (max-width: 680px) {
    min-height: fit-content;
  }
`;

const FeatureCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  height: 57px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-bottom: 1.5px solid #e2e5e9;
    padding-bottom: 15px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
      }
      width: 42px;
      height: 42px;
      background: #f6f7f9;
      box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
      border-radius: 12px;
    }

    h4 {
      font-family: ${(props) => props.$theme.secondaryFont};
      color: ${(props) => props.$theme.colors.dark};
      font-weight: 600;
      font-size: 21px;
      line-height: 110%;
      letter-spacing: 0.5px;
    }
  }
`;

const FutureContainer = styled.section`
  background: url(${(props) => props.$src}) no-repeat;
  background-size: cover;
  max-width: 1440px;
  min-height: fit-content;
  padding: 100px 120px 50px 120px;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 1000px;

  > div {
    min-width: 50% !important;
    padding-left: 20px;
    margin-top: 200px;
  }

  @media (max-width: 960px) {
    padding: 120px 15px;
    flex-direction: column;
    > div:first-child {
      display: none;
    }
    > div {
      min-width: 100% !important;
      margin-top: 0;
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    height: fit-content;
    background: url(${(props) => props.$src2}) no-repeat;
    background-size: contain;
    background-position: bottom right;
  }
`;

export default About;
